import type { ComponentType } from "react"

export function gradientAnimation(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                initial={{
                    background: `linear-gradient(200deg, #000000 0%, hsl(0, 0%, 0%) 100%)`,
                }}
                animate={{
                    background: `linear-gradient(148deg, #bdf2ff 0%, hsl(269, 47%, 74%) 100%)`,
                }}
                transition={{ ease: "easeInOut", duration: 2.3, delay: 0.5 }}
            />
        )
    }
}

export function withCopy(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                onClick={() => {
                    navigator.clipboard.writeText("hi@mattahrens.design")
                }}
            />
        )
    }
}
